import { Card, CardHeader, Grid, Link, Stack, Typography } from "@mui/material"
import Label from "../../../components/label/Label"
import { useGetRTKQSingleListingQuery } from "../../../redux/rtkQuery/apiSlice"
import { fCurrency } from "../../../utils/formatNumber"
import { FDateFormat, fDate } from "../../../utils/formatTime"
import { fBedrooms } from "../../../utils/mrr/fBedrooms"
import { LoadingDetails } from "../reservation/LoadingDetails"

interface ListingDetailsCardI {
    listingId: string,
    handleOpenSupportPopover: (event: React.MouseEvent<HTMLElement>) => void
}

export default function ListingDetailsCard(props: ListingDetailsCardI) {
    const { handleOpenSupportPopover, listingId } = props
    const {
        data: listing,
        isFetching,
        isSuccess
    } = useGetRTKQSingleListingQuery({ listingId: listingId })

    return (
        <>
            {isFetching && <LoadingDetails numRows={3} />}
            {isSuccess && <Card sx={{ padding: 2 }}>
                <Stack direction='row' justifyContent='space-between'>
                    <CardHeader
                        sx={{ padding: 0 }}
                        title={listing?.name_detailed}
                        subheader={listing ? listing.unit_view + '・' + fBedrooms(listing.bedrooms) + '・' + listing.bathrooms + ' BA' : ''}
                    />
                    <Label
                        color={listing?.stage_mapped === 'active' ? 'primary' : 'default'}
                        bgcolor={(theme) => listing?.stage_mapped === 'active' ? theme.palette.secondary.main : 'default'}
                    >
                        {listing?.stage_mapped}
                    </Label>
                </Stack>
                <Grid container direction='row'>
                    {/* spacing={4} */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Grid item>
                            <Typography variant='caption'>Lessor</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body1'>{listing?.lessor_name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Grid item>
                            <Typography variant='caption'>Support</Typography>
                        </Grid>
                        <Grid item>
                            <Link sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenSupportPopover(e)} variant='body1'>{listing?.owner_name}</Link>
                        </Grid>
                    </Grid>
                    {listing?.effective_date_ISO &&
                        <Grid item xs={12} sm={6} md={3}>
                            <Grid item>
                                <Typography variant='caption'>Effective Date</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>{listing?.effective_date_ISO ? fDate(listing.effective_date_ISO, FDateFormat.localized_us_long_month_day_year) : ''}</Typography>
                            </Grid>
                        </Grid>}
                    {listing?.minimum_rate &&
                        <Grid item xs={12} sm={6} md={3}>
                            <Grid item>
                                <Typography variant='caption'>Minimum Rate</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>{fCurrency(listing.minimum_rate)}</Typography>
                            </Grid>
                        </Grid>}
                </Grid>
            </Card>
            }
        </>
    )
}